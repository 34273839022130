import { Injectable } from '@angular/core';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConfigurationStore } from './configuration.reducer';
import { ConfigurationService } from '../../../../../admin/src/app/dashboard/set-ups/configuration/configuration.service';

@Injectable()
export class ConfigurationEffect {
  constructor(
    protected actions$: Actions,
    protected service: ConfigurationService,
  ) {}

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationStore.actions.fetchAll),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => ConfigurationStore.actions.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(ConfigurationStore.actions.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchSingle = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationStore.actions.fetchSingle),
      exhaustMap((action) =>
        this.service.fetchAll({ versionNo: action.versionNo }).pipe(
          map((r) => ConfigurationStore.actions.fetchSingleSuccess({ result: r.content[0] })),
          catchError((error) => of(ConfigurationStore.actions.fetchSingleError({ error }))),
        ),
      ),
    ),
  );

  checkIfVersionExists = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationStore.actions.checkIfVersionExists),
      exhaustMap((action) =>
        this.service.fetchAll({ versionNo: action.versionNo }).pipe(
          map((r) =>
            ConfigurationStore.actions.checkIfVersionExistsSuccess({
              result: { exists: r.content.some((x) => x.versionNo == action.versionNo) },
            }),
          ),
          catchError((error) => of(ConfigurationStore.actions.checkIfVersionExistsError({ error }))),
        ),
      ),
    ),
  );

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationStore.actions.create),
      exhaustMap((action) =>
        this.service.save(action.item).pipe(
          map((r) =>
            ConfigurationStore.actions.createSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(ConfigurationStore.actions.createError({ error }))),
        ),
      ),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationStore.actions.update),
      exhaustMap((action) =>
        this.service.save(action.item).pipe(
          map((r) =>
            ConfigurationStore.actions.updateSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(ConfigurationStore.actions.updateError({ error }))),
        ),
      ),
    ),
  );
}
