import { Injectable } from '@angular/core';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';
import { map } from 'rxjs';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import { IConfiguration, ISaveConfiguration } from './configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BaseService {
  protected override baseURL = 'v1/config';

  fetchAll(query?: { versionNo: number }) {
    return this.get<ISR<IConfiguration>>('', query);
  }

  save(data: ISaveConfiguration) {
    return data.id ? this.put<IConfiguration>(data) : this.post<IConfiguration>(data);
  }

}
