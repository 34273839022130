import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import {
  IConfiguration,
  ISaveConfiguration,
} from '../../../../../admin/src/app/dashboard/set-ups/configuration/configuration.model';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export namespace ConfigurationStore {
  //#region STATE
  export interface IState extends EntityState<IConfiguration> {
    fetchedAll: boolean;
  }

  const adapter: EntityAdapter<IConfiguration> = createEntityAdapter<IConfiguration>({
    sortComparer: (a, b) => a.versionNo - b.versionNo,
    selectId: (i) => i.id,
  });
  const initialState: IState = { ...adapter.getInitialState(), fetchedAll: false };
  //#endregion

  //#region ACTIONS
  export const actions = createActionGroup({
    source: 'Configuration API',
    events: {
      create: props<{ item: ISaveConfiguration }>(),
      createError: props<{ error: any }>(),
      createSuccess: props<{ result: IConfiguration }>(),
      fetchAll: emptyProps(),
      fetchAllError: props<{ error: any }>(),
      fetchAllSuccess: props<{ result: IConfiguration[] }>(),
      fetchSingle: props<{ versionNo: number }>(),
      fetchSingleError: props<{ error: any }>(),
      fetchSingleSuccess: props<{ result: IConfiguration }>(),
      checkIfVersionExists: props<{ versionNo: number }>(),
      checkIfVersionExistsError: props<{ error: any }>(),
      checkIfVersionExistsSuccess: props<{ result: { exists: boolean } }>(),
      update: props<{ item: ISaveConfiguration }>(),
      updateError: props<{ error: any }>(),
      updateSuccess: props<{ result: IConfiguration }>(),
    },
  });
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const select = (state: IAppState) => state.configuration;
    export const selectAll = createSelector(select, adapter.getSelectors().selectAll);

    export const selectAllCT = createSelector(selectAll, (r) =>
      r.map((x) => ({ code: x.versionNo, title: x.versionDate })),
    );
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.fetchAllSuccess, (state, { result }) => adapter.setAll(result, state)),
    on(actions.fetchAllSuccess, (state, { result }) => ({ ...state, fetchedAll: true })),
    on(actions.fetchSingleSuccess, (state, { result }) =>
      state.fetchedAll ? adapter.setOne(result, state) : state,
    ),
    on(actions.createSuccess, (state, { result }) =>
      state.fetchedAll ? adapter.setOne(result, state) : state,
    ),
    on(actions.updateSuccess, (state, { result }) =>
      state.fetchedAll ? adapter.setOne(result, state) : state,
    ),
  );
  //#endregion
}
